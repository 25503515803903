import React from 'react';
import PropTypes from 'prop-types';
import { BackButton } from 'common/buttons';
import { useFindPayer } from 'src/components/Backoffice/api/hooks/v1/payerHooks';
import { useFindPlan } from 'src/components/Backoffice/api/hooks/v1/planHooks';
import { useFindFeeSchedule } from 'src/components/Backoffice/api/hooks/v1/feeScheduleHooks';
import Breadcrumbs from 'common/Breadcrumbs';
import { browserHistory } from 'common/utils/browserHistory';
import { Spinner } from 'common/spinners';
import PlanSubHeader from './PlanSubHeader';
import PlanOverview from './PlanOverview';
import PlanEnrollmentsAttachments from './PlanEnrollmentsAttachments';
import PlanNetworkOrganizations from './PlanNetworkOrganizations';

const Plan = ({ params }) => {
  const payerId = params.payer_id;
  const planId = params.plan_id;
  const feeScheduleId = params.fee_schedule_id;

  const { data: plan, isLoading: isPlanLoading } = useFindPlan(planId);
  const planLabel = isPlanLoading ? 'Loading Program...' : plan.name;
  let crumbs;
  if (payerId) {
    const { data: payer, isLoading: isPayerLoading } = useFindPayer(payerId);
    const payerLabel = isPayerLoading ? 'Loading...' : payer.name;
    crumbs = [
      { label: 'Payers', href: '/backoffice/payers' },
      { label: payerLabel, href: `/backoffice/payers/${payer?.id}` },
      { label: planLabel },
    ];
  } else {
    const { data: feeSchedule, isLoading: isFeeScheduleLoading } = useFindFeeSchedule(feeScheduleId);
    const feeScheduleLabel = isFeeScheduleLoading ? 'Loading...' : feeSchedule.name;
    crumbs = [
      { label: 'Fee Schedules', href: '/backoffice/fee_schedules' },
      {
        label: feeScheduleLabel,
        href: `/backoffice/fee_schedules/${feeScheduleId}`,
      },
      {
        label: 'Plans',
        href: `/backoffice/fee_schedules/${feeScheduleId}/plans`,
      },
      { label: planLabel },
    ];
  }

  const childTabComponent = () => {
    const tabUrl = window.location.pathname.split('/').pop();
    switch (tabUrl) {
      case 'attachments':
        return <PlanEnrollmentsAttachments plan={plan} />;
      case 'networks':
        return <PlanNetworkOrganizations plan={plan} />;
      default:
        return <PlanOverview plan={plan} />;
    }
  };

  const BackToPlans = () => {
    const planPath = `/backoffice/payers/${payerId}`;
    const feeSchedulePath = `/backoffice/fee_schedules/${feeScheduleId}/plans`;
    browserHistory.push({
      pathname: feeScheduleId ? feeSchedulePath : planPath,
    });
  };

  return (
    <>
      {isPlanLoading ? (
        <Spinner />
      ) : (
        <div className="detail-view">
          <div className="flex p-4 justify-start items-center bg-white border-b border-solid border-dark-border-blue">
            <div>
              <BackButton navigateTo={BackToPlans} />
            </div>
            <div className="px-5">
              <Breadcrumbs className="bread-crumbs" crumbs={crumbs} />
            </div>
          </div>
          <div className="bg-white border-b border-solid border-dark-border-blue mb-0">
            <PlanSubHeader
              plan={plan}
              payerId={payerId}
              feeScheduleId={feeScheduleId}
            />
          </div>
          {childTabComponent()}
        </div>
      )}
    </>
  );
};

Plan.propTypes = {
  params: PropTypes.object.isRequired,
};

export default Plan;
