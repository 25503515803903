import moment from 'moment';

const constructPlanInitialFormValues = (plan, networks = [], requiredHealthPlanPayers, requiredHealthPlans) => {
  const initialValues = plan;
  if (initialValues && initialValues.starts_at) {
    initialValues.starts_at = moment(initialValues.starts_at).format('MM/DD/YYYY');
  }
  if (initialValues && initialValues.ends_at) {
    initialValues.ends_at = moment(initialValues.ends_at).format('MM/DD/YYYY');
  }
  if (initialValues && initialValues.enrollment_required !== undefined) {
    initialValues.enrollment_required = initialValues.enrollment_required.toString();
  }
  if (networks && networks.length) {
    initialValues.networks = networks.map((network) => ({ id: network.id, name: network.name }));
  }
  if (initialValues && requiredHealthPlans?.length && requiredHealthPlanPayers?.length) {
    initialValues.health_insurance_required = 'true';
    initialValues.required_health_plans = requiredHealthPlans;
    initialValues.required_health_plan_payers = requiredHealthPlanPayers;
  }
  return initialValues;
};

export default constructPlanInitialFormValues;
