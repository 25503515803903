import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DEFAULT_ENUMS from 'reducers/constants/defaultEnums';

const formatFieldName = (field) => _.startCase(field.replace(/_/g, ' '));

const formatValue = (value) => {
  if (value === null || value === 'prefer_not_to_disclose') return null;
  return value;
};

const DisplayField = ({ fieldName, value }) => {
  if (!value) return null;

  return (
    <div className="flex flex-col">
      <span className="font-bold text-sm">{formatFieldName(fieldName)}</span>
      <span className="text-gray-700">{value}</span>
    </div>
  );
};

const TextField = ({ fieldName, value }) => {
  const formattedValue = formatValue(value);
  return <DisplayField fieldName={fieldName} value={formattedValue} />;
};

const EnumField = ({ fieldName, value, enumsPath }) => {
  const enumValues = _.get(DEFAULT_ENUMS, enumsPath, []);
  const displayValue = enumValues.find((item) => item.value === value)?.display_name || formatValue(value);
  return <DisplayField fieldName={fieldName} value={displayValue} />;
};

const FullMilitaryInfo = ({ militaryData }) => (
  <div className="space-y-4">
    {Object.entries(militaryData).map(([key, value]) => {
      const enumsPath = `contact_data.military.${key}`;
      const isEnumField = key in DEFAULT_ENUMS.contact_data.military;

      return isEnumField ? (
        <EnumField
          key={key}
          fieldName={key}
          value={value}
          enumsPath={enumsPath}
        />
      ) : (
        <DisplayField key={key} fieldName={key} value={formatValue(value)} />
      );
    })}
  </div>
);

const MilitaryDisplay = ({ militaryData }) => {
  if (!militaryData || Object.keys(militaryData).length === 0) return null;

  const { affiliation } = militaryData;
  if (!affiliation) return null;

  return (
    <div className="flex flex-col mt-[-4px] space-y-4">
      <FullMilitaryInfo militaryData={militaryData} />
    </div>
  );
};

MilitaryDisplay.propTypes = {
  militaryData: PropTypes.object.isRequired,
};

DisplayField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

TextField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

EnumField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  enumsPath: PropTypes.string.isRequired,
};

FullMilitaryInfo.propTypes = {
  militaryData: PropTypes.object.isRequired,
};

export default MilitaryDisplay;
