import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { browserHistory } from 'common/utils/browserHistory';
import {
  useFindAllPlan,
  useFindPlan,
  useUpdatePlan,
} from 'src/components/Backoffice/api/hooks/v1/planHooks';
import { useFindPayers } from 'src/components/Backoffice/api/hooks/v1/payerHooks';
import { constructPlanInitialFormValues, constructPlanFormPayload } from '../../utils';
import PlanForm from './PlanForm';

const EditPlan = ({ params }) => {
  const planId = params.plan_id;
  const { status, data: planData } = useFindPlan(planId);
  const requiredHealthPlanIds = planData?.required_health_plans?.length ?
    planData.required_health_plans.map((plan) => (plan.id)).join(',') : '';
  const { data: requiredHealthPlansData, isLoading: isLoadingHealthPlans } = useFindAllPlan(
    requiredHealthPlanIds,
    {
      queryConfig: {
        enabled: !!requiredHealthPlanIds,
        placeholderData: undefined,
      },
    },
  );
  const requiredHealthPlans = requiredHealthPlansData?.data?.data;
  const requiredHealthPlanPayerIds = requiredHealthPlans?.length ?
    requiredHealthPlans.map((healthPlan) => (healthPlan.payer.id)).join(',') : '';
  const { data: requiredHealthPlanPayersData, isLoading: isLoadingHealthPlanPayers } = useFindPayers(
    requiredHealthPlanPayerIds,
    {
      queryConfig: {
        enabled: !!requiredHealthPlanIds,
        placeholderData: undefined,
      },
    },
  );
  const requiredHealthPlanPayers = requiredHealthPlanPayersData?.data?.data;
  const payerId = params.payer_id ?? planData.payer?.id;
  const feeScheduleId = params.fee_schedule_id ?? '';

  const { updateRecord: updatePlan } = useUpdatePlan();
  const initialValues = constructPlanInitialFormValues(
                                                        planData,
                                                        requiredHealthPlanPayers,
                                                        requiredHealthPlans,
                                                      );
  const showPending = (status === 'loading' && isEmpty(initialValues)) ||
    isLoadingHealthPlans ||
    isLoadingHealthPlanPayers;

  const onSubmit = async (plan) => {
    const payload = constructPlanFormPayload(plan, payerId);
    const response = await updatePlan(planId, payload);
    const payersPath = `/backoffice/payers/${payerId}/plans/${planId}`;
    const feeSchedulePath = `/backoffice/fee_schedules/${feeScheduleId}/plans/${planId}`;
    if (response) {
      browserHistory.push({
        pathname: feeScheduleId ? feeSchedulePath : payersPath,
      });
    }
  };

  return (
    <PlanForm
      initialValues={initialValues} onSubmit={onSubmit} payerId={payerId}
      planId={planId} showPending={showPending} feeScheduleId={feeScheduleId}
    />
  );
};

EditPlan.propTypes = {
  params: PropTypes.object.isRequired,
};

export default EditPlan;
