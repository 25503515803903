import { isEmpty } from 'lodash';
import { useFind, useFindRecord } from 'src/api/APIHooks';
import { defaultQueryConfig } from '../apiHookOptions';

export const useFindPayer = (id) => useFindRecord('payer', id, {
  queryConfig: { ...defaultQueryConfig, placeholderData: undefined },
});

export const useFindPayers = (ids, params) => useFind(
  'payer',
  { id: ids },
  !isEmpty(params) ? params : { queryConfig: { ...defaultQueryConfig, placeholderData: undefined } },
);

export const useFindPayersByName = (
  searchTerm,
  params,
) => useFind(
  'payer',
  { name: searchTerm || '' },
  !isEmpty(params) ? params : { queryConfig: { ...defaultQueryConfig, placeholderData: undefined } },
);

export function useFindPayerOptions(searchTerm) {
  const { data: payerData, isSuccess } = useFindPayersByName(
    searchTerm,
    { queryConfig: { ...defaultQueryConfig, placeholderData: undefined } },
  );

  return isSuccess ? payerData : [];
}
