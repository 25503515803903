import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import { Form, Field } from 'react-final-form';
import _ from 'lodash';

import { Spinner } from 'common/spinners';
import { BackButton } from 'common/buttons';

import { Button, Icon } from '@unite-us/ui';
import { UULogoLoader } from '@unite-us/client-utils';

import DollarAmount from 'common/display/Money/DollarAmount';

import { MULTIPLE_VALUES_ATTRIBUTES } from '../utils/mergeClientData';
import enumDisplayName from '../utils/enumDisplayName';

import Address from './Address';
import Nicknames from './Nicknames';
import Name from './Name';
import MilitaryDisplay from './MilitaryDisplay';
import EmailAddress from './EmailAddress';
import HouseholdValue from './HouseholdValue';
import PhoneNumber from './PhoneNumber';
import SexualityDisplay from './SexualityDisplay';
import MethodOfContact from './MethodOfContact';
import Timeslots from './Timeslots';
import ContactNote from './ContactNote';
import PreferredLanguages from './PreferredLanguages';
import { filterMilitaryInformation } from '../utils/filterMilitaryInformation';

const required = (value) => (value ? undefined : 'Required');

const validateForm = (values) => {
  const errors = {};
  Object.keys(values).forEach((key) => {
    if (MULTIPLE_VALUES_ATTRIBUTES.includes(key)) {
      return;
    }
    const error = required(values[key]);
    if (error) {
      errors[key] = error;
    }
  });

  return errors;
};

const formatDate = (date) => moment(date).format('MM/DD/YY');

const FieldType = {
  gross_monthly_income: 'money',
  marital_status: 'enum',
  gender: 'enum',
  race: 'enum',
  ethnicity: 'enum',
  citizenship: 'enum',
  date_of_birth: 'date',
  household: 'household',
  sexual_orientation: 'sexuality',
  email_addresses: 'email_address',
  phone_numbers: 'phone_number',
  methods_of_contact: 'method_of_contact',
  timeslots: 'timeslots',
  contact_notes: 'contact_note',
  addresses: 'address',
  nicknames: 'nicknames',
  name: 'name',
  military_information: 'military_information',
  record_languages: 'record_languages',
};

const FieldKey = {
  email_addresses: 'email_address',
  phone_numbers: 'phone_number',
  addresses: 'id',
  methods_of_contact: 'id',
  timeslots: 'id',
  contact_notes: 'id',
};

const FieldValue = ({ field, value }) => {
  switch (FieldType[field]) {
    case 'enum':
      return enumDisplayName(value, `people.${field}`);
    case 'money':
      return <DollarAmount value={value} />;
    case 'date':
      return formatDate(value);
    case 'email_address':
      return <EmailAddress email={value} />;
    case 'household':
      return <HouseholdValue value={value} />;
    case 'sexuality':
      return <SexualityDisplay value={value} />;
    case 'phone_number':
      return <PhoneNumber phone={value} />;
    case 'address':
      return <Address address={value.attributes} />;
    case 'nicknames':
      return <Nicknames nicknames={value} />;
    case 'name':
      return <Name {...value} />;
    case 'military_information':
      return <MilitaryDisplay militaryData={value} />;
    case 'method_of_contact':
      return <MethodOfContact value={value} />;
    case 'timeslots':
      return <Timeslots value={value} />;
    case 'contact_note':
      return <ContactNote value={value} />;
    case 'record_languages':
      return <PreferredLanguages recordLanguages={value} />;
    default:
      return value;
  }
};

const MultipleFieldsRow = ({
  values, field, clientIds, selectedValues,
}) => {
  const fieldName = _.startCase((field.replace(/_/g, ' ')));

  return (
    <div className="w-full gap-2 grid grid-cols-custom" role="listitem" data-test-id="multiple-fields-row">
      <div className="text-brand-blue font-bold font-heavy-font p-2 flex col-span-1">
        {fieldName}
      </div>
      {values.map((fieldValues, index) => (
        <div
          key={`${clientIds[index]}-${field}`}
          className="flex flex-col space-y-2 w-full"
        >
          {fieldValues.map((value) => (
            <Field
              key={`client_${field}_${value[FieldKey[field]]}`}
              name={field}
              type="checkbox"
              value={value}
              id={value[FieldKey[field]]}
            >
              {({ input }) => (
                <label
                  className={cx(
                    'ui-checkbox-field flex items-center font-initial p-2',
                    {
                      'bg-light-fill-grey':
                        selectedValues
                          ?.some((selectedValue) => selectedValue[FieldKey[field]] === value[FieldKey[field]]),
                    },
                  )}
                >
                  <input
                    {...input}
                    id={`client_${field}_${value[FieldKey[field]]}`}
                    type="checkbox"
                  />
                  <div className="ui-form-field__label mb-0" />
                  <FieldValue field={field} value={value} />
                </label>
              )}
            </Field>
          ))}
        </div>
      ))}
    </div>
  );
};

const FIELD_DISPLAY_NAMES = {
  timeslots: 'Times To Contact',
};

const FieldRow = ({
  field, selectedValues, clientIds, values,
}) => {
  const fieldDisplayName = FIELD_DISPLAY_NAMES[field] || _.startCase(field);
  const processedValues = field === 'military_information' ? filterMilitaryInformation(values) : values;

  return (
    <div
      className="grid grid-cols-custom gap-2 w-full ui-radio-field ui-radio-field--inline ui-form-field mb-0"
      role="listitem"
      data-test-id="field-row"
    >
      <div
        className={cx(
          'text-brand-blue font-bold font-heavy-font p-2 flex',
          { 'items-center': field !== 'military_information' },
        )}
      >
        {fieldDisplayName}
      </div>
      {processedValues.map((value, index) => (
        <div
          key={`${clientIds[index]}-${field}`}
          className={cx(
            'p-2 flex',
            {
              'bg-light-fill-grey': selectedValues === index.toString(),
              'items-center': field !== 'military_information',
            },
          )}
        >
          <div className="ui-radio-field__item">
            <label htmlFor={`client-${index}-${field}`}>
              <div
                className={cx(
                  'flex gap-1',
                  { 'items-center': field !== 'military_information' },
                )}
              >
                <Field
                  id={`client-${index}-${field}`}
                  name={field}
                  component="input"
                  type="radio"
                  value={index.toString()}
                  validate={required}
                  aria-labelledby={`client-${index}-${field}`}
                />
                <span className="normal-case" />
                <FieldValue field={field} value={value} />
              </div>
            </label>
          </div>
        </div>
      ))}
    </div>
  );
};

const MergeForm = ({
  confirmMergeClients,
  openConfirmationModal,
  isLoading,
  firstClientData,
  secondClientData,
  differentFields,
  clientsComparison,
}) => {
  const hasLoadingError = !isLoading && (!firstClientData || !secondClientData || !clientsComparison);
  const hasDifferences = Object.keys(differentFields).length > 0;

  return (
    <Form
      onSubmit={confirmMergeClients}
      validate={validateForm}
      render={({
        handleSubmit, submitting, values, errors,
      }) => (
        submitting ? (
          <div
            className="flex flex-col items-center mt-12 py-32 w-1/2 bg-white border
                  border-solid rounded-t-md border-filter-border-color"
          >
            <UULogoLoader height={100} />
            <span className="mt-5">Merging client profiles. Don&apos;t refresh this page.</span>
          </div>
        ) : (
          <form
            onSubmit={(event) => {
              event.preventDefault();
              openConfirmationModal(() => handleSubmit(event));
            }}
          >
            <div className="flex flex-col items-center w-full max-w-5xl space-y-4">
              <BackButton className="self-start" />
              <div className="w-full">
                <div className="px-8 py-4 bg-white border border-solid rounded-t-md border-filter-border-color">
                  <h2 className="text-xl font-semibold text-brand-blue">
                    Select Client Profile Information to Keep
                  </h2>
                  <p className="my-2 text-sm text-brand-blue">
                    Only information that differs between the two client records appears below.
                    Select which information you want to keep.
                    We&apos;ll also save all of the matching profile information from both records,
                    plus all of the referral history.
                  </p>
                  <div className="flex items-center gap-2">
                    <Icon
                      className="fill-current text-brand-blue"
                      icon={'V2Warning'}
                      size={14}
                    />
                    <span>
                      This action can&apos;t be undone.
                    </span>
                  </div>
                </div>
                <div
                  className="flex flex-col items-center py-8 px-40 space-y-4 bg-white border-b border-l
                        border-r border-solid rounded-b-md border-filter-border-color"
                  role="list"
                >
                  {isLoading && <Spinner />}
                  {hasLoadingError && <div>Error loading client data</div>}
                  {!isLoading && !hasLoadingError && (
                    <div
                      className={cx('w-full flex flex-col items-center space-y-2', { 'space-y-10': !hasDifferences })}
                    >
                      <div
                        className={cx(
                          'w-full gap-2',
                          {
                            'grid grid-cols-custom': hasDifferences,
                            'flex justify-center': !hasDifferences,
                          },
                        )}
                      >
                        <div />
                        {[firstClientData, secondClientData].map((client) => (
                          <div
                            key={client.id}
                            className="
                          flex flex-col py-3 w-56
                          px-4 border border-solid rounded-md
                        border-filter-border-color
                        "
                          >
                            <h3 className="mb-1 text-base font-semibold text-action-blue">{client.full_name}</h3>
                            <div className="flex flex-col gap-1">
                              <span className="italic">Last Updated: {formatDate(client.updated_at)}</span>
                              <span className="italic">Date Created: {formatDate(client.created_at)}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                      {Object.keys(differentFields).map((field) => {
                        const isMultiValueField = MULTIPLE_VALUES_ATTRIBUTES.includes(field);
                        const FieldComponent = isMultiValueField ? MultipleFieldsRow : FieldRow;
                        return (
                          <FieldComponent
                            key={`field-row-${field}`}
                            field={field}
                            clientIds={[firstClientData.id, secondClientData.id]}
                            values={[differentFields[field][0], differentFields[field][1]]}
                            selectedValues={values[field]}
                          />
                        );
                      })}
                      {hasDifferences ? (
                        <div className="pt-6">
                          Showing only fields with different values.
                        </div>
                      ) : (
                        <div className="italic">
                          All of the client details match in these records. Select Merge Records to continue.
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full flex justify-end">
                <Button
                  type="submit"
                  primary
                  label="Merge Records"
                  className="w-40"
                  disabled={
                    hasDifferences &&
                    Object.keys(errors).length > 0
                  }
                />
              </div>
            </div>
          </form>
        )
      )}
    />
  );
};

FieldValue.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};

FieldRow.defaultProps = {
  selectedValues: null,
};

FieldRow.propTypes = {
  field: PropTypes.string.isRequired,
  clientIds: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  selectedValues: PropTypes.any,
};

MultipleFieldsRow.defaultProps = {
  selectedValues: null,
};

MultipleFieldsRow.propTypes = {
  values: PropTypes.arrayOf(PropTypes.array).isRequired,
  field: PropTypes.string.isRequired,
  clientIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedValues: PropTypes.any,
};

MergeForm.defaultProps = {
  clientsComparison: null,
};

MergeForm.propTypes = {
  confirmMergeClients: PropTypes.func.isRequired,
  openConfirmationModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  firstClientData: PropTypes.object.isRequired,
  secondClientData: PropTypes.object.isRequired,
  differentFields: PropTypes.object.isRequired,
  clientsComparison: PropTypes.object,
};

export default MergeForm;
