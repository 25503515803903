import React from 'react';
import { get } from 'lodash';
import { browserHistory } from 'common/utils/browserHistory';
import Notifier from 'common/helpers/Notifier';
import {
  useCreateDirectory,
} from 'src/components/Backoffice/api/hooks/prdApi/DirectoryHooks';
import { useInvalidateQueries } from 'src/api/APIHooks';
import PrdForm from './components/PrdForm';

const CreatePrd = () => {
  const invalidateQueries = useInvalidateQueries();

  const { createRecord: createPrd } = useCreateDirectory({
    onError: (error) => {
      const errorList = get(error, 'response.data.errors', []);
      const stringNotification = errorList.map((e) => e.title).join(', ');
      Notifier.dispatch(400, `Failed to create directory: ${stringNotification}`);
    },
    onSuccess: () => {
      invalidateQueries('directories');
      Notifier.dispatch(200, 'PRD successfully created');
    },
  });

  const onSubmit = async (prd) => {
    const prdData = {
      ...prd,
      networks: prd.networks?.map((network) => network.id),
    };

    const response = await createPrd(prdData);
    if (response) {
      browserHistory.push({ pathname: '/backoffice/prds' });
    }
  };

  return (
    <PrdForm onSubmit={onSubmit} />
  );
};

export default CreatePrd;
