export const filterMilitaryInformation = (values) => {
  const [first, second] = values;

  if (first.affiliation !== second.affiliation) {
    return values.map(({ affiliation }) => ({ affiliation }));
  }

  if (first.affiliation === 'military_member_or_veteran') {
    return values.map((obj) => {
      const filtered = { affiliation: obj.affiliation };
        Object.keys(obj).forEach((key) => {
          if (first[key] !== second[key]) {
            filtered[key] = obj[key];
          }
        });
      return filtered;
    });
  }

  return values;
};
