import { isEmpty } from 'lodash';
import {
  useSetRelationship,
  useCreateRecord,
  useFind,
  useFindRecord,
  useInvalidateQueries,
  useUpdateRecord,
} from 'src/api/APIHooks';
import { NON_SOCIAL_PLAN_TYPES } from 'src/pages/backoffice/payers/_id/plans/_plan_id/constants';
import { defaultQueryConfig, defaultMutationConfig } from '../apiHookOptions';

const filterForPlanIds = (payers) => {
  const planIds = [];
  payers.forEach((payer) => {
    const plans = payer.plans;
    if (plans.length !== 0) {
      planIds.push(plans.map((plan) => (plan.id)));
    }
  });
  return planIds.join(',');
};

export const useCreatePlan = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useCreateRecord('plan', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('plan');
        invalidateQueries('payer');
        invalidateQueries('network');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export const useFindAllPlan = (ids, params = { queryConfig: defaultQueryConfig }) => useFind(
  'plans',
  {
    id: ids,
  },
  {
    queryConfig: {
      placeholderData: undefined,
      enabled: !!ids,
    },
    ...params,
  },
);

export const useFindPlan = (id, params = { queryConfig: defaultQueryConfig }) => useFindRecord('plan', id, {
  queryConfig: {
    placeholderData: undefined,
    enabled: !!id,
  },
  ...params,
});

export const useFindPlansByName = (planName, params = { queryConfig: defaultQueryConfig }) => useFind(
  'plans',
  {
    name: planName,
  },
  {
    queryConfig: {
      enabled: !!planName,
      placeholderData: undefined,
    },
    ...params,
  },
);

export const useSetPlanFeeSchedule = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useSetRelationship('plan', 'fee_schedule', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('plan');
        invalidateQueries('fee_schedule');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export const useUpdatePlan = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useUpdateRecord('plan', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('plan');
        invalidateQueries('payer');
        invalidateQueries('network');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export const useFindRequiredHealthPlanOptions = (payers) => {
  const payerPlanIds = filterForPlanIds(payers);

  const { data: requiredHealthPlanResponse, isLoading } = useFind(
    'plans',
    {
      id: payerPlanIds,
      plan_type: NON_SOCIAL_PLAN_TYPES.join(','),
    },
    {
      queryConfig: {
        enabled: !isEmpty(payerPlanIds),
        placeholderData: undefined,
      },
    },
  );
  const requiredHealthPlanOptions = requiredHealthPlanResponse?.data?.data;

  return (!isEmpty(requiredHealthPlanOptions) && !isLoading) ? requiredHealthPlanOptions : [];
};

export default useFindPlan;
