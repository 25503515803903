import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { uniq } from 'lodash';
import { Field } from 'react-final-form';
import { SelectField } from '@unite-us/ui';
import { validations } from '@unite-us/app-components';
import composeValidators from 'src/components/Organization/utils/composeValidators';
import { useFindPayerOptions } from 'src/components/Backoffice/api/hooks/v1/payerHooks';
import { useFindRequiredHealthPlanOptions } from 'src/components/Backoffice/api/hooks/v1/planHooks';

const HealthInsurancePlanSelectField = ({ initialValues, changeValue }) => {
  const initialRequiredHealthPlanPayers = (initialValues.required_health_plan_payers) ?
  initialValues.required_health_plan_payers : [];
  const initialRequiredHealthPlans = (initialValues.required_health_plans) ?
  initialValues.required_health_plans : [];

  const [payerSearch, setPayerSearch] = useState('');
  const [payers, setPayers] = useState(initialRequiredHealthPlanPayers);
  const [requiredHealthPlans, setRequiredHealthPlans] = useState(initialRequiredHealthPlans);

  const getPayerOptions = useFindPayerOptions(payerSearch);
  const getRequiredHealthPlanOptions = useFindRequiredHealthPlanOptions(payers);
  changeValue('required_health_plans', requiredHealthPlans);

  useEffect(() => {
    const payerIds = payers.map((payer) => (payer.id));
    const newSelectedPlans = requiredHealthPlans.filter((plan) => payerIds.includes(plan?.payer?.id));
    setRequiredHealthPlans(newSelectedPlans);
  }, [payers]);

  return (
    <div className="md:col-span-6 xl:col-span-6 px-0 flex">
      <Field
        name="required_health_plan_payers"
        validate={composeValidators(validations.isRequired)}
      >
        {(props) => (
          <SelectField
            className="md:col-span-6 xl:col-span-6 px-0"
            id="plan__required_health_plan_payers"
            label="Payer(s)"
            multiple
            onChange={(value) => {
              setPayers(value);
            }}
            loadOptions={(value) => {
              setPayerSearch(value);
            }}
            options={uniq([...payers, ...getPayerOptions])}
            placeholder="Select"
            renderChoiceLimit={50}
            searchChoices={false}
            shouldSearchOnChange
            forceObjectValue
            labelKey="name"
            valueKey="id"
            required
            {...props}
          />
        )}
      </Field>
      <Field
        name="required_health_plans"
        validate={composeValidators(validations.isRequired)}
      >
        {(props) => (
          <SelectField
            className="md:col-span-6 xl:col-span-6 px-0"
            id="plan__required_health_plans"
            label="Health Insurance Plan(s)"
            multiple
            onChange={(value) => {
              setRequiredHealthPlans(value);
            }}
            options={getRequiredHealthPlanOptions}
            placeholder="Select"
            renderChoiceLimit={50}
            searchChoices={false}
            shouldSearchOnChange
            forceObjectValue
            labelKey="name"
            valueKey="id"
            required
            {...props}
          />
        )}
      </Field>
    </div>
  );
};

HealthInsurancePlanSelectField.propTypes = {
  initialValues: PropTypes.object.isRequired,
  changeValue: PropTypes.func.isRequired,
};

export default HealthInsurancePlanSelectField;
